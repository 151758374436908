document.addEventListener("click", function(e) {
  // Close open nav & dropdowns
  if (e.target && !e.target.closest('.menu-item-has-children, .mega-menu')) {
    if(document.querySelector('.mega-menu.open')){
      document.querySelector('.mega-menu.open').classList.remove('open');
    }
    
  }
  if (e.target && e.target.closest('.menu-item-has-children')) {
    e.preventDefault();
    e.target.closest('.menu-item-has-children').nextElementSibling.classList.add('open');
  }
});
