
let downloadForm = document.querySelector('.download-form');


if (downloadForm) {
  let fileNameField = downloadForm.querySelector('input.filename');
fileNameField.value = downloadForm.dataset.filename;

  document.addEventListener( 'wpcf7mailsent', function( e ) { //change back to "sent" not "submit"
    
    let link = downloadForm.dataset.link + '?d=' + downloadForm.dataset.file;
    window.location.href = link;
  }, false );
}

