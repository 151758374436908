// import $ from "jquery";
// var Isotope = require("isotope-layout");

// // quick search regex
// // var qsRegex;
// var filterSelectsValues = "*";
// // var filterSelector2 = "*";

// // init Isotope
// var postGrid = document.querySelector(".live-grid");
// if (postGrid) {
//   var iso = new Isotope(postGrid, {
//     itemSelector: ".post",
//     layoutMode: "fitRows",
//     margin: 100,  
//     filter: function () {
//       var post = jQuery(this)[0];
      
//       if (filterSelectsValues.constructor === Array){
//         var allValid = true;
//         filterSelectsValues.forEach(function(el){
//           if (!post.dataset.cat.includes(el)) {  
//             allValid = false;
//           }    
//         });

//         if (!allValid) {
//           return false;
//         }

//       } else if (filterSelectsValues != "*") {
//         return false;
//       }    

//       return true;
//     },
//   });
// }

// const filterSelects = document.querySelectorAll(".live-filters select");
// if (filterSelects) {
//   document.addEventListener("change", function (e) {    
//     if (!e.target.closest(".live-filters select")) {
//       return;
//     }
//     updateFilters();
//   });
// }

// const updateFilters = () => {
//   filterSelectsValues = [];

//   filterSelects.forEach(function (el) {
//     el.value != "*" ? filterSelectsValues.push(el.value) : null;
//   });
//   filterSelectsValues.length == 0
//     ? (filterSelectsValues = "*")
//     : filterSelectsValues;

//   iso.arrange();
// };


// //reset
// document.addEventListener("click", function (e) {
//   if (e.target.closest(".live-filters .reset")) {
//     filterSelects.forEach(function (el) {
//       el.selectedIndex = 0;
//     });
//     updateFilters();
//   }
 
// });