// Event snippet for Submit Contact Form 2022 conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-626113759/4r-rCJ2_6qIDEN_5xqoC',
      'event_callback': callback
  });
  return false;
}

// .wpcf7-submit
document.addEventListener("click", function (e) {
    
    if (e.target.closest('.wpcf7-submit')) {
        gtag_report_conversion();
        
    }
});